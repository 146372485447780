@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow&display=swap);
@font-face {
  font-family: "sq-hank-rnd";
  src: url(/static/media/HankRnd-Regular.ef52037d.ttf), url(/static/media/HankRnd-Regular.a7511fdb.otf);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background: #ffffff 0% 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiInputBase-input {
  font-family: "Nunito Sans", sans-serif;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch
  .MuiSwitch-colorSecondary.Mui-checked
  + .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-colorSecondary.Mui-checked {
  color: #0e1c4e;
}

.customRibbon {
  width: 40px;
  height: 36px;
  position: relative;
  background: #fa7e61;
}

.customRibbon:before {
  content: "";
  position: absolute;
  right: 22px;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.customRibbon:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pricing-carousel .carousel-root {
  width: 100%
}

.pricing-carousel .carousel-status{
  visibility: hidden;
}

.sitehmbr  {
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;  
}

.WebStyles_blueHeaderTxt__2jkiK {
  color: #72cac9;
}
.WebStyles_samonHeaderTxt__wuNfv {
  color: #f37c61;
}
.WebStyles_paraBlackText__2NKvG {
  color: #2b3350;
}
.WebStyles_purpleTxt__3oA8W {
  color: #b872af;
}

.WebStyles_captionGray__2N-tA {
  color: #64626a;
}
.WebStyles_captionViolet__2OGCt {
  color: #B872AF;
}
.WebStyles_captionBlack__103fc {
  color:#1B1E4C;
}
.WebStyles_roundedBtn__13uDo {
  color: #ffffff;
  background-color: #72cac9;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 20px;
  text-transform: capitalize !important;
}

.WebStyles_nunitoFont__1Ex3K {
  font-family: "Nunito Sans", sans-serif;
}

.WebStyles_hankrndFont__1tCCe {
  font-family: "sq-hank-rnd", sans-serif;
}

.WebStyles_barlowFont__jtM_V {
  font-family: 'Barlow', sans-serif;
}

.WebStyles_socialIcon__1VHSm {
  width: 24px;
  height: 24px;
}

.WebStyles_socialIconMobile__3mUWN {
  width: 16px;
  height: 16px;
}

.WebStyles_navyBlueColor__pk2CQ {
  color: #0e1c4e;
}

.WebStyles_sqEllipsis__3gU13 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.WebStyles_font14__2fE_O  { 
  font-size: 0.875rem !important;
}

.WebStyles_sqEllipsisOneLine__cf-63 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}
