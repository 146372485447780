.blueHeaderTxt {
  color: #72cac9;
}
.samonHeaderTxt {
  color: #f37c61;
}
.paraBlackText {
  color: #2b3350;
}
.purpleTxt {
  color: #b872af;
}

.captionGray {
  color: #64626a;
}
.captionViolet {
  color: #B872AF;
}
.captionBlack {
  color:#1B1E4C;
}
.roundedBtn {
  color: #ffffff;
  background-color: #72cac9;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 20px;
  text-transform: capitalize !important;
}

.nunitoFont {
  font-family: "Nunito Sans", sans-serif;
}

.hankrndFont {
  font-family: "sq-hank-rnd", sans-serif;
}

.barlowFont {
  font-family: 'Barlow', sans-serif;
}

.socialIcon {
  width: 24px;
  height: 24px;
}

.socialIconMobile {
  width: 16px;
  height: 16px;
}

.navyBlueColor {
  color: #0e1c4e;
}

.sqEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.font14  { 
  font-size: 0.875rem !important;
}

.sqEllipsisOneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}